// vendors
import React from "react"
import { css } from "@emotion/core"
import InputMask from "react-input-mask"
import { Formik, Form, FieldArray } from "formik"
import * as Yup from "yup"

import Input from "../../../components/Input"

import { fontSizes } from "../../../styles/variables"
import mediaQuery from "../../../utils/media-query"
import SegementedControls from "../../../components/SegementedControls/SegementedControls"
import Button from "../../../components/Button/Button"
import Checkbox from "../../../components/Checkbox/Checkbox"

import { FormGroup, Label, ErrorMessage } from "./../style"
import TextArea from "../../../components/TextArea"
import serializeData from "../../../utils/serializeData"
import regionsList from "../../../data/regions"
import universities from "../../../data/universities"

const formValidateSchema = Yup.object().shape({
  firstname: Yup.string().required("Information incomplète"),
  lastname: Yup.string().required("Information incomplète"),
  email: Yup.string()
    .email("Adresse courriel invalide")
    .required("Information incomplète"),
  message: Yup.string().required("Information incomplète"),
})

const JobForm = ({ breakpoint, onSubmited = () => {}, hidden }) => {
  const handleSubmit = (values, { setSubmitting }) => {
    const data = {
      "mauticform[formId]": 4,
      "mauticform[selectedregions]": values.selectedRegions.join("|"),
      "mauticform[firstname]": values.firstname,
      "mauticform[lastname]": values.lastname,
      "mauticform[telephone]": values.telephone,
      "mauticform[email]": values.email,
      "mauticform[status]": values.status,
      "mauticform[university]": values.university,
      "mauticform[f_message]": values.message,
    }

    fetch("https://mautic.equipesarros.ca/form/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
      },
      body: serializeData(data),
    })
      .then(() => {
        onSubmited()
      })
      .catch((error) => alert(error))

    setSubmitting(false)
  }

  return (
    <div hidden={hidden}>
      <Formik
        initialValues={{
          selectedRegions: [],
          firstname: "",
          lastname: "",
          telephone: "",
          email: "",
          university: "",
          status: "",
          message: "",
          "bot-field": "",
        }}
        validationSchema={formValidateSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            method="post"
            css={css`
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              grid-column-gap: 36px;

              ${mediaQuery.greaterThen(breakpoint)} {
                grid-template-columns: repeat(2, 1fr);
              }
            `}
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="formId" value="4" />
            <p hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>

            {/* Régions */}
            <fieldset
              css={css`
                grid-column: span 1;
                border: none;
                width: 100%;
                padding: 0;
                margin: ${fontSizes[8] / fontSizes[1]}em 0;

                ${mediaQuery.greaterThen(breakpoint)} {
                  grid-column: span 2;
                }
              `}
            >
              <legend
                css={css`
                  font-size: ${fontSizes[4] / fontSizes[1]}em;
                  font-weight: bold;
                `}
              >
                Choisissez une ou des régions(s)
              </legend>

              <FieldArray
                name="selectedRegions"
                render={(arrayHelpers) => (
                  <div
                    css={css`
                      width: 100%;
                      column-width: 260px;
                      column-gap: 20px;
                    `}
                  >
                    {regionsList.map((region) => (
                      <Checkbox
                        name="selectedRegions"
                        value={region.value}
                        label={region.label}
                        checked={values.selectedRegions.includes(region.value)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            arrayHelpers.push(region.value)
                          } else {
                            const idx = values.selectedRegions.indexOf(
                              region.value
                            )
                            arrayHelpers.remove(idx)
                          }
                        }}
                      />
                    ))}
                  </div>
                )}
              />
            </fieldset>

            {/* Prénom */}
            <FormGroup
              css={css`
                grid-column: 1 / span 1;
                margin-bottom: ${fontSizes[5] / fontSizes[1]}em;
              `}
            >
              <Label htmlFor="input-firstname">Prénom</Label>
              <Input
                type="text"
                id="input-firstname"
                name="firstname"
                value={values.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.firstname && touched.firstname}
              />
              <ErrorMessage>
                {errors.firstname && touched.firstname && (
                  <p>{errors.firstname}</p>
                )}
              </ErrorMessage>
            </FormGroup>

            {/* Nom */}
            <FormGroup
              css={css`
                grid-column: span 1;
              `}
            >
              <Label htmlFor="input-lastname">Nom</Label>

              <Input
                type="text"
                id="input-lastname"
                name="lastname"
                value={values.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lastname && touched.lastname}
              />

              <ErrorMessage>
                {errors.lastname && touched.lastname && (
                  <p>{errors.lastname}</p>
                )}
              </ErrorMessage>
            </FormGroup>

            {/* Courriel */}
            <FormGroup
              css={css`
                grid-column: span 1;
              `}
            >
              <Label htmlFor="input-email">Courriel</Label>
              <Input
                type="email"
                id="input-email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email}
              />

              <ErrorMessage>
                {errors.email && touched.email && <p>{errors.email}</p>}
              </ErrorMessage>
            </FormGroup>

            {/* Téléphone */}
            <FormGroup
              css={css`
                grid-column: span 1;
              `}
            >
              <Label htmlFor="input-telephone">Téléphone</Label>

              <InputMask
                mask="999-999-9999"
                value={values.telephone}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {(inputProps) => (
                  <Input
                    type="tel"
                    {...inputProps}
                    id="input-telephone"
                    name="telephone"
                    placeholder="123-123-1234"
                    error={errors.telephone && touched.telephone}
                    css={css`
                      width: auto;
                      display: inline;
                    `}
                  />
                )}
              </InputMask>

              <ErrorMessage>
                {errors.telephone && touched.telephone && (
                  <p>{errors.telephone}</p>
                )}
              </ErrorMessage>
            </FormGroup>

            {/* Université */}
            <FormGroup
              css={css`
                grid-column: span 1;

                ${mediaQuery.greaterThen(breakpoint)} {
                  grid-column: span 2;
                }
              `}
            >
              <Label htmlFor="input-university">Université</Label>

              <SegementedControls
                type="text"
                id="input-university"
                name="university"
                value={values.university}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.university && touched.university}
                wrap
                options={universities}
                css={css`
                  width: 100%;
                `}
              />

              <ErrorMessage>
                {errors.university && touched.university && (
                  <p>{errors.university}</p>
                )}
              </ErrorMessage>
            </FormGroup>

            {/* Statut */}
            <FormGroup
              css={css`
                grid-column: span 1;

                ${mediaQuery.greaterThen(breakpoint)} {
                  grid-column: span 2;
                }
              `}
            >
              <Label htmlFor="input-status">Statut</Label>

              <SegementedControls
                type="text"
                id="input-status"
                name="status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.status && touched.status}
                breakpoint="550"
                options={[
                  { label: "Résident", value: "resident" },
                  { label: "Médecin pratiquant", value: "pratiquant" },
                  { label: "Autre", value: "autre" },
                ]}
              />

              <ErrorMessage>
                {errors.status && touched.status && <p>{errors.status}</p>}
              </ErrorMessage>
            </FormGroup>

            {/* message */}
            <FormGroup
              css={css`
                grid-column: span 1;

                ${mediaQuery.greaterThen(breakpoint)} {
                  grid-column: span 2;
                }
              `}
            >
              <Label htmlFor="textArea-message">
                Informations complémentaires
              </Label>
              <TextArea
                id="textArea-message"
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.message && touched.message}
              />

              <ErrorMessage>
                {errors.message && touched.message && <p>{errors.message}</p>}
              </ErrorMessage>
            </FormGroup>

            <Button full secondary type="submit" disabled={isSubmitting}>
              Envoyer ma demande
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default JobForm
